import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingColor: false,
  setLoadingColor: (v) => {},
  AllColors: [],
  setAllColors: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  text: '',
  setText: (id) => {},
  selectedColor: null,
  setSelectedColor: (servKey) => {},
  count: false,
  setCount: (v) => {}
};

const ColorsContext = createContext(INITIAL_VALUES);

export const ColorsProvider = ({ children }) => {
  const [loadingColor, setLoadingColor] = useState(INITIAL_VALUES.loadingColor);
  const [AllColors, setAllColors] = useState(INITIAL_VALUES.AllColors);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [text, setText] = useState(INITIAL_VALUES.text);
  const [selectedColor, setSelectedColor] = useState(INITIAL_VALUES.selectedColor);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <ColorsContext.Provider
      value={{
        loadingColor,
        setLoadingColor,
        AllColors,
        setAllColors,
        selectedServId,
        setSelectedServId,
        text,
        setText,
        selectedColor,
        setSelectedColor,
        count,
        setCount
      }}>
      {children}
    </ColorsContext.Provider>
  );
};

export default ColorsContext;
