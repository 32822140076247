import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingModal: false,
  setLoadingModal: (v) => {},
  modalOpen: false,
  setModalOpen: (v) => {},
  modalComponent: null,
  setModalComponent: (v) => {}
};

const ModalContext = createContext(INITIAL_VALUES);

export const ModalProvider = ({ children }) => {
  const [loadingModal, setLoadingModal] = useState(INITIAL_VALUES.loadingModal);
  const [modalComponent, setModalComponent] = useState(INITIAL_VALUES.modalComponent);
  const [modalOpen, setModalOpen] = useState(INITIAL_VALUES.modalOpen);
  const [modelWidth, setModelWidth] = useState('500px');
  return (
    <ModalContext.Provider
      value={{
        loadingModal,
        setLoadingModal,
        modalComponent,
        setModalComponent,
        modalOpen,
        setModalOpen,
        modelWidth,
        setModelWidth
      }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
