import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { postToApi } from './apis/apis';
import { Store } from 'react-notifications-component';
import UesrContext from './contexts/user-context/UserProvider';
import routerLinks from './components/app/routerLinks';

const useSignout = () => {
  const navigate = useNavigate();
  const { removeCurrentUser } = useContext(UesrContext);
  const [isLoadingSignout, setIsLoadingSignout] = React.useState(false);
  const signMeOut = async () => {
    try {
      setIsLoadingSignout(true);
      const res = await postToApi('/admin-logout', null);
      if (res.response_code === 200) {
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: res?.message || 'تم تسجيل الخروج بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        navigate(routerLinks?.loginPage);
        removeCurrentUser();
      }
    } catch (e) {}
  };

  return { signMeOut, isLoadingSignout, setIsLoadingSignout };
};

export default useSignout;
