import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingCoupon: false,
  setLoadingCoupon: (v) => {},
  AllCoupons: [],
  setAllCoupons: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  text: '',
  setText: (id) => {},
  selectedCoupon: null,
  setSelectedCoupon: (servKey) => {},
  count: false,
  setCount: (v) => {}
};

const CouponsContext = createContext(INITIAL_VALUES);

export const CouponsProvider = ({ children }) => {
  const [loadingCoupon, setLoadingCoupon] = useState(INITIAL_VALUES.loadingCoupon);
  const [AllCoupons, setAllCoupons] = useState(INITIAL_VALUES.AllCoupons);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [text, setText] = useState(INITIAL_VALUES.text);
  const [selectedCoupon, setSelectedCoupon] = useState(INITIAL_VALUES.selectedCoupon);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <CouponsContext.Provider
      value={{
        loadingCoupon,
        setLoadingCoupon,
        AllCoupons,
        setAllCoupons,
        selectedServId,
        setSelectedServId,
        text,
        setText,
        selectedCoupon,
        setSelectedCoupon,
        count,
        setCount
      }}>
      {children}
    </CouponsContext.Provider>
  );
};

export default CouponsContext;
