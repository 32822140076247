import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingSetting: false,
  setLoadingSetting: (v) => {},

  count: false,
  setCount: (v) => {}
};

const SettingsContext = createContext(INITIAL_VALUES);

export const SettingsProvider = ({ children }) => {
  const [loadingSetting, setLoadingSetting] = useState(INITIAL_VALUES.loadingSetting);
  const [features, setFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const [sliders, setSliders] = useState([]);
  const [selectedSlider, setSelectedSlider] = useState(null);
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [settingsInfo, setSettingsInfo] = useState(null);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <SettingsContext.Provider
      value={{
        loadingSetting,
        setLoadingSetting,
        features,
        setFeatures,
        selectedFeature,
        setSelectedFeature,
        sliders,
        setSliders,
        selectedSlider,
        setSelectedSlider,
        payments,
        setPayments,
        selectedPayment,
        setSelectedPayment,
        settingsInfo,
        setSettingsInfo,
        count,
        setCount
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
