/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext } from 'react';

import { create } from 'jss';
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core';
import rtl from 'jss-rtl';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
import Loading from '../../common/loading/Loading';
// import 'antd/dist/antd.css';
import Routess from './Routess';
import '../../i18n';
import AppLayout from './Layout';
import { useEffect } from 'react';
import axios from 'axios';
// import i18next from 'i18next';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalContext from '../../contexts/modal-context/ModalProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import LoginPage from '../../pages/login-page/LoginPage';
import { ReactNotifications } from 'react-notifications-component';
import { getFromApi } from '../../apis/apis';
import EstateDetailsContext from '../../contexts/estate-details-context/EstateDetailsContext';

// axios.defaults.baseURL = 'http://mazad.fatoorah.sa/api';
// axios.defaults.baseURL = 'https://mazad.wgresa.com/api';
axios.defaults.baseURL = 'https://backendmazad.almaharh.com/api';

function App() {
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  // useEffect(()=> {
  //   if() {
  //     i18next.changeLanguage('ar');
  //   }
  // }, [])
  const { modalOpen, setModalOpen, modalComponent, modelWidth } = useContext(ModalContext);
  const { loggedIn } = useContext(UesrContext);
  const { user } = useContext(UesrContext);
  axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '90vh',
    overflowY: 'scroll',
    transform: 'translate(-50%, -50%)',
    width: modelWidth,
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '21px'
  };

  // console.log(allFeatures);
  const { setIsLoading, setFetchedDetails, fetchCount } = useContext(EstateDetailsContext);

  useEffect(() => {
    const getFeatures = async () => {
      try {
        setIsLoading(true);
        const response = await getFromApi(`/estate-details`);
        setIsLoading(false);
        if (response.response_code === 200) {
          setFetchedDetails(response.data);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    getFeatures();
  }, [i18n.language, fetchCount]);

  return (
    // <Fade>
    <>
      <div className={`app app-${i18n.dir()}`}>
        <ThemeProvider theme={theme(i18n.dir())}>
          <StylesProvider jss={jss}>
            <ReactNotifications className={i18n.dir()} />
            <Suspense fallback={<Loading />}>
              {/* <Routes> */}
              {!loggedIn ? (
                <LoginPage />
              ) : (
                <AppLayout>
                  <Routess />
                </AppLayout>
              )}
              {/* <LoginPage /> */}
              {/* </Routes> */}
            </Suspense>
          </StylesProvider>
        </ThemeProvider>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          // setMo
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>{modalComponent}</Box>
      </Modal>
    </>
    // </Fade>
  );
}

export default App;
