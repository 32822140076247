import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../contexts/modal-context/ModalProvider';
import closeimg from '../../assets/imgs/icons/closeimg.png';

import { changePassword } from '../../apis/auth/changePassword';
import MUITextField from '../../common/material-ui-components/MUITextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button, Form } from 'antd';

import { Store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
const schema = Yup.object().shape({
  old_password: Yup.string().required('من فضلك ادخل الرمز السري القديم '),
  new_password: Yup.string().required('من فضلك ادخل الرمز السري الجديد'),
  confirm_new_password: Yup.string().required('من فضلك ادخل تأكيد الرمز السري الجديد')
});
const ChangePassword = () => {
  const { setModalOpen } = useContext(ModalContext);
  const { user } = useContext(UesrContext);

  const { i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    }
  });
  const onSubmit = async (data) => {
    try {
      const res = await changePassword(data, user?.token, i18n.language);
      console.log('res: ', res);
      if (res.response_code !== 200) {
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        console.log('success');
        // setModalComponent(<ResetPassword />);
        setModalOpen(false);
        Store.addNotification({
          title: 'تم التعديل',
          message: res?.data?.message || 'تم تعديل الباسورد',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const [form] = Form.useForm();

  return (
    <div className="add-category-form">
      <div className="modal-header">
        <div className="modal-name">
          <p className="name">تغيير كلمة السر</p>
        </div>
        <div className="close">
          <button
            onClick={() => {
              setModalOpen(false);
            }}>
            <img src={closeimg} alt="close" />
          </button>
        </div>
      </div>
      <div>
        <Form
          className="login-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}>
          <div className="form-body">
            <MUITextField
              name="old_password"
              type="password"
              placeholder="كلمة السر القديمه"
              // label="الاســــم" password
              errorMsg={errors?.old_password?.message}
              validateStatus={errors?.old_password ? 'error' : ''}
              control={control}
            />
            <MUITextField
              name="new_password"
              type="password"
              placeholder="كلمة السر"
              // label="الاســــم" password
              errorMsg={errors?.new_password?.message}
              validateStatus={errors?.new_password ? 'error' : ''}
              control={control}
            />
            <MUITextField
              name="confirm_new_password"
              type="password"
              placeholder="تأكيد كلمة السر"
              // label="الاســــم" password
              errorMsg={errors?.confirm_new_password?.message}
              validateStatus={errors?.confirm_new_password ? 'error' : ''}
              control={control}
            />
            <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
              تغيير كلمه السر
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
