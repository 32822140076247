import axios from 'axios';

export const editProfile = async (values, token, langIsoCode) => {
  const { name, email, phone } = values;
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);

    // if (image && image[0]) {
    //   formData.append('image', image[0]);
    // }

    const signinRes = await axios.post('/update-admin-profile', formData, {
      headers: {
        lang: langIsoCode,
        Authorization: `Bearer ${token}`
      }
    });
    //console.log('signin response: ', signinRes);
    return signinRes;
  } catch (error) {
    // //console.log(error);
  }
};
