import React from 'react';
import Logo from '../../common/logo/Logo';

import LoginForm from './LoginForm';
import './LoginPage.scss';
const LoginPage = () => {
  return (
    <>
      <div className="login-page">
        <div className="custom-container">
          <Logo className="page-logo" />
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
