import axios from 'axios';

export const changePassword = async (values, token, langIsoCode) => {
  const { old_password, new_password, confirm_new_password } = values;
  try {
    const formData = new FormData();
    formData.append('old_password', old_password);

    formData.append('new_password', new_password);
    formData.append('confirm_new_password', confirm_new_password);

    const signinRes = await axios.post('/change-admin-password', formData, {
      headers: {
        lang: 'ar',
        Authorization: `Bearer ${token}`
      }
    });
    // //console.log('signin response: ', signinRes);
    return signinRes.data;
  } catch (error) {
    // //console.log(error);
  }
};
