const { createContext, useState } = require('react');

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedDetails: null,
  setFetchedDetails: (v) => {},
  newEstate: false,
  setNewEstate: (v) => {}
};

const EstateDetailsContext = createContext(INITIAL_VALUES);

export const EstateDetailsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedDetails, setFetchedDetails] = useState(INITIAL_VALUES.fetchedDetails);
  const [newEstate, setNewEstate] = useState(INITIAL_VALUES.newEstate);

  return (
    <EstateDetailsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchedDetails,
        setFetchedDetails,
        fetchCount,
        setFetchCount,
        newEstate,
        setNewEstate
      }}>
      {children}
    </EstateDetailsContext.Provider>
  );
};

export default EstateDetailsContext;
