import React, { useContext, useEffect } from 'react';

import UesrContext from '../../contexts/user-context/UserProvider';

import * as Yup from 'yup';
import { Button, Form } from 'antd';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Store } from 'react-notifications-component';

import MUITextField from '../../common/material-ui-components/MUITextField';

import { editProfile } from '../../apis/auth/editProfile';
import ChangePassword from './ChangePassword';
import ModalContext from '../../contexts/modal-context/ModalProvider';
// import UploadProfileFile from './UploadFeatureFile';

const ProfileForm = () => {
  const { user, setCurrentUser } = useContext(UesrContext);
  const { setModalComponent, setModalOpen } = useContext(ModalContext);

  let schema;

  schema = Yup.object().shape({
    name: Yup.string().required('من فضلك ادخل الاسم'),
    email: Yup.string().required('من فضلك ادخل  البريد الاكتروني'),
    phone: Yup.string().required('من فضلك ادخل رقم الهاتف')
  });

  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    // register,
    // watch,
    // unregister,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  });
  const onSubmit = async (data) => {
    // console.log('data : ', data);
    try {
      let res;
      res = await editProfile(data, user?.token);
      console.log(res);
      if (res.status === 200 && res.data.response_code !== 200) {
        Store.addNotification({
          title: 'حدث خطأ',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.response_code === 200) {
        // console.log('sdssdd');
        Store.addNotification({
          title: 'تم بنجاح',
          message: res?.data?.message || 'تمت العملية بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });

        setCurrentUser({ ...user, ...res.data.data, token: user?.token });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      const { name, phone, email } = user;
      setValue('name', name);
      setValue('phone', phone);
      setValue('email', email);
    }
  }, [user, setValue]);

  //   const [urls, setUrls] = useState([]);
  //   useEffect(() => {
  //     if (user?.image) {
  //       //   console.log('selected Author: ', selectedAuthor);
  //       setUrls([user?.image]);
  //     }
  //   }, [user]);
  return (
    <div className="info-tab">
      <div>
        <Form
          className="login-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}>
          <div className="form-body form-grid">
            <div className="social">
              {/* <div>
                <UploadProfileFile
                  accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                  maxFiles={1}
                  name="image"
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  unregister={unregister}
                  multiple={false}
                  label={<>صورة </>}
                  urls={urls}
                  errorMsg={errors?.icon ? errors?.icon?.message : ''}
                />
              </div>
              <div></div> */}
              <div>
                <MUITextField
                  name="name"
                  type="text"
                  placeholder="الاسم بالكامل"
                  errorMsg={errors?.name?.message}
                  validateStatus={errors?.name ? 'error' : ''}
                  control={control}
                />
              </div>
              <div>
                <MUITextField
                  name="phone"
                  type="text"
                  placeholder="رقم الهاتف"
                  errorMsg={errors?.phone?.message}
                  validateStatus={errors?.phone ? 'error' : ''}
                  control={control}
                />
              </div>
              <div>
                <MUITextField
                  name="email"
                  type="text"
                  placeholder="البريد الألكتروني"
                  errorMsg={errors?.email?.message}
                  validateStatus={errors?.email ? 'error' : ''}
                  control={control}
                />
              </div>
              <div className="password">
                <div
                  onClick={() => {
                    setModalComponent(<ChangePassword />);
                    setModalOpen(true);
                  }}>
                  تغيير كلمه السر
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="form-body">
            <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
              تعديل
            </Button>
          </div>
        </Form>
      </div>
      {/* <div className="img">
        <img src={OBJECTS} alt="OBJECTS" />
      </div> */}
    </div>
  );
};

export default ProfileForm;
