import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingOrder: false,
  setLoadingOrder: (v) => {},
  AllOrders: [],
  setAllOrders: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  text: '',
  setText: (id) => {},
  selectedOrder: null,
  setSelectedOrder: (servKey) => {},
  count: false,
  setCount: (v) => {}
};

const OrdersContext = createContext(INITIAL_VALUES);

export const OrdersProvider = ({ children }) => {
  const [loadingOrder, setLoadingOrder] = useState(INITIAL_VALUES.loadingOrder);
  const [AllOrders, setAllOrders] = useState(INITIAL_VALUES.AllOrders);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [text, setText] = useState(INITIAL_VALUES.text);
  const [selectedOrder, setSelectedOrder] = useState(INITIAL_VALUES.selectedOrder);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <OrdersContext.Provider
      value={{
        loadingOrder,
        setLoadingOrder,
        AllOrders,
        setAllOrders,
        selectedServId,
        setSelectedServId,
        text,
        setText,
        selectedOrder,
        setSelectedOrder,
        count,
        setCount
      }}>
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersContext;
