import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button, Form } from 'antd';

import { signinApi } from '../../apis/auth/signin';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import './LoginForm.scss';
import UesrContext from '../../contexts/user-context/UserProvider';
import MUITextField from '../../common/material-ui-components/MUITextField';
import { useNavigate } from 'react-router-dom';

const schema = Yup.object().shape({
  phone: Yup.string().required('من فضلك ادخل البريد الألكتروني'),
  password: Yup.string().required('من فضلك ادخل الرقم السرى')
});

const LoginForm = () => {
  const { t, i18n } = useTranslation();
  const { setCurrentUser } = useContext(UesrContext);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      phone: '',
      password: ''
    }
  });

  const onSubmit = async (data) => {
    // console.log('data : ', data);
    try {
      const res = await signinApi(data, i18n.language);
      if (res.status === 200 && res.data.response_code !== 200) {
        Store.addNotification({
          title: 'حدث خطأ اثناء الدخول',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.response_code === 200) {
        if (data.remember) {
          setCurrentUser({ ...res.data.data });
          navigate('/');
        } else setCurrentUser({ ...res.data.data });

        // setModalComponent(null);
        // setModalOpen(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // console.log('user : ', user);

  const [form] = Form.useForm();
  return (
    <Form className="login-form" form={form} layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <h2>تسجيل الدخول</h2>

      <div className="form-body">
        <MUITextField
          name="phone"
          type="text"
          placeholder="البريد الألكتروني"
          // label="الاســــم"
          errorMsg={errors?.phone?.message}
          validateStatus={errors?.phone ? 'error' : ''}
          control={control}
        />
        <MUITextField
          name="password"
          type="password"
          placeholder={t('login_page.login_form.password')}
          // label="الرقــم الســرى" password
          errorMsg={errors?.password?.message}
          validateStatus={errors?.password ? 'error' : ''}
          control={control}
        />

        {/* <MUICheckbox
          name="remember"
          label={t('login_page.login_form.remember')}
          control={control}
        /> */}
        <div className="btn">
          <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
            {t('login_page.login_form.btn')}
          </Button>
        </div>

        {/* <Link to={`/`} className='forget-password'>{t('login_page.login_form.forgetPassword')}</Link> */}
      </div>
    </Form>
  );
};

export default LoginForm;
