import React, { useState } from 'react';
import './NewItem.scss';
import Countdown from './Countdown';
import ArchiveItems from './ArchiveItems';
import PhotosPage from '../photos-page/PhotosPage';
import Infoform from './Infoform';

const NewItem = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const renderPage = () => {
    if (currentPage === 1) {
      return <Infoform />;
    } else if (currentPage === 2) {
      return <PhotosPage />;
    } else if (currentPage === 3) {
      return <Countdown></Countdown>;
    } else if (currentPage === 4) {
      return <ArchiveItems />;
    }
  };
  return (
    <div className="item-page">
      <div className="tab-items">
        <div
          onClick={() => {
            setCurrentPage(1);
          }}>
          أضافه/تعديل القيم
        </div>

        <div
          onClick={() => {
            setCurrentPage(2);
          }}>
          أضافة صورة
        </div>

        <div
          onClick={() => {
            setCurrentPage(3);
          }}>
          أعدادات العد التنازلي
        </div>

        {/* <div
          onClick={() => {
            setCurrentPage(4);
          }}>
          أرشيف
        </div> */}
      </div>
      <>{renderPage()}</>
    </div>
  );
};

export default NewItem;
