import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingProduct: false,
  setLoadingProduct: (v) => {},
  AllProducts: [],
  setAllProducts: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedProduct: null,
  setSelectedProduct: (servKey) => {},
  text: '',
  setText: (id) => {},
  count: false,
  setCount: (v) => {}
};

const ProductContext = createContext(INITIAL_VALUES);

export const ProductProvider = ({ children }) => {
  const [loadingProduct, setLoadingProduct] = useState(INITIAL_VALUES.loadingProduct);
  const [AllProducts, setAllProducts] = useState(INITIAL_VALUES.AllProducts);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedProduct, setSelectedProduct] = useState(INITIAL_VALUES.selectedProduct);

  const [text, setText] = useState(INITIAL_VALUES.text);

  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <ProductContext.Provider
      value={{
        loadingProduct,
        setLoadingProduct,
        AllProducts,
        setAllProducts,
        selectedServId,
        setSelectedServId,
        selectedProduct,
        setSelectedProduct,
        text,
        setText,
        count,
        setCount
      }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;
