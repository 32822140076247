/* eslint-disable eqeqeq */
import { Upload, message, Modal, Radio } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { getFromApi, postToApi, displayError } from '../../apis/apis';
import './PhotosPage.scss';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const PhotosPage = () => {
  const [fileType, setFileType] = useState('1');

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [isValid, setIsValid] = useState(false);
  //
  const [previewVideo, setPreviewVideo] = useState('');
  const [previewVideoTitle, setPreviewVideoTitle] = useState('');
  const [previewVideoVisible, setPreviewVideoVisible] = useState(false);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    if (fileType === '1') {
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isImg = file.name.match(/(svg|gif|jpe?g|tiff?|png|webp|bmp)$/i);
      if (!isImg) {
        setIsLoading(false);
        setIsValid(false);
        message.error('You can only upload image file!');
      } else {
        setIsValid(true);
      }
      return isImg;
    } else if (fileType === '2') {
      const isVideo = file.name.match(/(\.mp4|\.wmv|\.avi|\.flv|\.webm|\.mkv|\.avchd|\.f4v)$/i);
      if (!isVideo) {
        setIsLoading(false);
        setIsValid(false);
        message.error('You can only upload video file!');
      } else {
        setIsValid(true);
      }
      return isVideo;
    }
  }

  const handlePreview = async (file) => {
    if (file?.apiType == 1) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    } else if (file?.apiType == 2) {
      setPreviewVideo(file.url || file.preview);
      setPreviewVideoVisible(true);
      setPreviewVideoTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    }
  };

  const [estateDetails, setEstateDetails] = useState(null);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const details = async () => {
      try {
        setIsLoadingData(true);
        const response = await getFromApi('/estate-details');
        setIsLoadingData(false);
        if (response?.data) {
          setEstateDetails(response?.data);
          // setLoadingHome(false);
        } else {
          // setLoadingHome(false);
        }
      } catch (error) {
        setIsLoadingData(false);
        // setLoadingHome(false);
      }
    };
    details();
  }, [count]);

  useEffect(() => {
    if (estateDetails?.data?.images?.length >= 0) {
      setFileList(
        estateDetails?.data.images.map((img) => ({
          uid: img?.id,
          apiType: img?.type,
          name: `${img?.id}`,
          status: 'done',
          url: img?.url
        }))
      );
    }
  }, [estateDetails?.data?.images]);

  const onRemove = async (file) => {
    try {
      const formData = new FormData();
      formData.append('id', file?.uid);
      const res = await postToApi('/delete-estate-image', formData);
      if (res?.response_code == 200 && res?.success) {
        setCount((prev) => prev + 1);
      } else {
        displayError();
      }
    } catch (e) {
      displayError(e);
    }
  };

  const shared = () => {
    return (
      <>
        <Radio.Group
          size="large"
          style={{
            marginTop: 32,
            marginBottom: 32
          }}
          optionType="button"
          buttonStyle="solid"
          onChange={(v) => {
            setFileType(v?.target.value);
          }}
          value={fileType}
          options={[
            {
              label: 'إرفع صورة',
              value: '1'
            },
            {
              label: 'إرفع فيديو',
              value: '2'
            }
          ]}>
          <Radio value="image">إرفع صورة</Radio>
          <Radio value="video">إرفع فيديو</Radio>
        </Radio.Group>

        <>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            // showUploadList={false}
            fileList={fileList}
            action={async (file) => {
              try {
                if (isValid) {
                  setIsLoading(true);
                  const formData = new FormData();
                  formData.append('id', estateDetails?.data?.id);
                  formData.append('type', fileType);
                  formData.append('url', file);
                  const res = await postToApi('/upload-estate-image', formData);
                  if (res?.response_code == 200 && res?.success) {
                    setIsLoading(false);
                    setCount((prev) => prev + 1);
                    setIsValid(false);
                  } else {
                    displayError();
                    setIsLoading(false);
                    setIsValid(false);
                  }
                }
              } catch (e) {
                setIsLoading(false);
                displayError(e);
                setIsValid(false);
              }
            }}
            beforeUpload={beforeUpload}
            onPreview={handlePreview}
            onRemove={onRemove}
            // onChange={handleChange}
          >
            {uploadButton}
            {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
          </Upload>
        </>
      </>
    );
  };

  return (
    <div className="photos-page">
      <div className="custom-container">
        {isLoadingData ? (
          <LoadingOutlined
            style={{
              marginTop: 32
            }}
          />
        ) : (
          shared()
        )}
      </div>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>

      <Modal
        visible={previewVideoVisible}
        title={previewVideoTitle}
        footer={null}
        onCancel={() => setPreviewVideoVisible(false)}>
        <video width="100%" controls>
          <source src={previewVideo} />
        </video>
      </Modal>
    </div>
  );
};

export default PhotosPage;
