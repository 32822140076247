import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import { ShopProvider } from '../../contexts/shop-context/ShopProvider';

import HomePage from '../../pages/home-page/HomePage';

import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
// import ProductPage from '../../pages/products-page/ProductPage';
import ProfilePage from '../../pages/profile-page/ProfilePage';

// import OrdersPage from '../../pages/orders-page/OrdersPage';
import routerLinks from './routerLinks';

const Routess = () => {
  return (
    <Routes>
      <Route exact path={routerLinks.homePage} element={<HomePage />}></Route>
      {/* <Route exact path={routerLinks.OrdersPage} element={<OrdersPage />}></Route> */}

      {/* <ShopProvider>  */}

      {/* </ShopProvider> */}

      <Route exact path={routerLinks.profilePage} element={<ProfilePage />}></Route>

      {/* not found page ShopPage */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routess;
