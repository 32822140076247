import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/app/App';
import Loading from './common/loading/Loading';

import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';

// import 'antd/dist/antd.less';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';
import './scss/index.scss';
import { UserProvider } from './contexts/user-context/UserProvider';
import { ShopProvider } from './contexts/shop-context/ShopProvider';
import { OccasionProvider } from './contexts/occasions-context/OccasionProvider';
import { CategorysProvider } from './contexts/categorys-context/CategorysProvider';
import { DesginersProvider } from './contexts/desginers-context/DesginersProvider';
import { ModalProvider } from './contexts/modal-context/ModalProvider';
import { ProductProvider } from './contexts/product-context/ProductsProvider';
import { SettingsProvider } from './contexts/settings-context/SettingsProvider';
import { ColorsProvider } from './contexts/colors-context/ColorsProvider';
import { OrdersProvider } from './contexts/orders-context/OrdersProvider';
import { CouponsProvider } from './contexts/coupons-context/CouponsProvider';
import { EstateDetailsProvider } from './contexts/estate-details-context/EstateDetailsContext';

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    {/* <Suspense> */}
    <HelmetProvider>
      <ProductProvider>
        <ModalProvider>
          <UserProvider>
            <Router>
              <SettingsProvider>
                <ShopProvider>
                  <CategorysProvider>
                    <DesginersProvider>
                      <OccasionProvider>
                        <CouponsProvider>
                          <ColorsProvider>
                            <OrdersProvider>
                              <EstateDetailsProvider>
                                <App />
                              </EstateDetailsProvider>
                            </OrdersProvider>
                          </ColorsProvider>
                        </CouponsProvider>
                      </OccasionProvider>
                    </DesginersProvider>
                  </CategorysProvider>
                </ShopProvider>
              </SettingsProvider>
            </Router>
          </UserProvider>
        </ModalProvider>
      </ProductProvider>
    </HelmetProvider>
  </Suspense>,
  document.getElementById('root')
);
