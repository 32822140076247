import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingDesginer: false,
  setLoadingDesginer: (v) => {},
  AllDesginers: [],
  setAllDesginers: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  text: '',
  setText: (id) => {},
  selectedDesginer: null,
  setSelectedDesginer: (servKey) => {},
  count: false,
  setCount: (v) => {}
};

const DesginersContext = createContext(INITIAL_VALUES);

export const DesginersProvider = ({ children }) => {
  const [loadingDesginer, setLoadingDesginer] = useState(INITIAL_VALUES.loadingDesginer);
  const [AllDesginers, setAllDesginers] = useState(INITIAL_VALUES.AllDesginers);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [text, setText] = useState(INITIAL_VALUES.text);
  const [selectedDesginer, setSelectedDesginer] = useState(INITIAL_VALUES.selectedDesginer);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <DesginersContext.Provider
      value={{
        loadingDesginer,
        setLoadingDesginer,
        AllDesginers,
        setAllDesginers,
        selectedDesginer,
        setSelectedDesginer,
        selectedServId,
        setSelectedServId,
        text,
        setText,
        count,
        setCount
      }}>
      {children}
    </DesginersContext.Provider>
  );
};

export default DesginersContext;
