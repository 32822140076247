import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingOccasion: false,
  setLoadingOccasion: (v) => {},
  AllOccasions: [],
  setAllOccasions: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  text: '',
  setText: (id) => {},
  selectedOccasion: null,
  setSelectedOccasion: (servKey) => {},
  count: false,
  setCount: (v) => {}
};

const OccasionContext = createContext(INITIAL_VALUES);

export const OccasionProvider = ({ children }) => {
  const [loadingOccasion, setLoadingOccasion] = useState(INITIAL_VALUES.loadingOccasion);
  const [AllOccasions, setAllOccasions] = useState(INITIAL_VALUES.AllOccasions);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [text, setText] = useState(INITIAL_VALUES.text);

  const [selectedOccasion, setSelectedOccasion] = useState(INITIAL_VALUES.selectedOccasion);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <OccasionContext.Provider
      value={{
        loadingOccasion,
        setLoadingOccasion,
        AllOccasions,
        setAllOccasions,
        selectedServId,
        setSelectedServId,
        selectedOccasion,
        setSelectedOccasion,
        text,
        setText,
        count,
        setCount
      }}>
      {children}
    </OccasionContext.Provider>
  );
};

export default OccasionContext;
