import axios from 'axios';

export const signinApi = async (values, langIsoCode) => {
  const { phone, password } = values;
  try {
    const formData = new FormData();
    formData.append('email', phone);
    formData.append('password', password);
    // formData.append('device_token', '');
    const signinRes = await axios.post('/admin-login', formData, {
      headers: {
        lang: langIsoCode
      }
    });
    // //console.log('signin response: ', signinRes);
    return signinRes;
  } catch (error) {
    // //console.log(error);
  }
};
