import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingCategory: false,
  setLoadingCategory: (v) => {},
  AllCategorys: [],
  setAllCategorys: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  text: '',
  setText: (id) => {},
  selectedCategory: null,
  setSelectedCategory: (servKey) => {},
  count: false,
  setCount: (v) => {}
};

const CategorysContext = createContext(INITIAL_VALUES);

export const CategorysProvider = ({ children }) => {
  const [loadingCategory, setLoadingCategory] = useState(INITIAL_VALUES.loadingCategory);
  const [AllCategorys, setAllCategorys] = useState(INITIAL_VALUES.AllCategorys);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [text, setText] = useState(INITIAL_VALUES.text);
  const [selectedCategory, setSelectedCategory] = useState(INITIAL_VALUES.selectedCategory);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <CategorysContext.Provider
      value={{
        loadingCategory,
        setLoadingCategory,
        AllCategorys,
        setAllCategorys,
        selectedServId,
        setSelectedServId,
        text,
        setText,
        selectedCategory,
        setSelectedCategory,
        count,
        setCount
      }}>
      {children}
    </CategorysContext.Provider>
  );
};

export default CategorysContext;
