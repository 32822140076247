const routerLinks = {
  loginPage: '/',
  homePage: '/',
  profilePage: '/profile',
  photosRoute: '/photos',
  ////////////////////////////
  ////////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;
