/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './Countdown.scss';
import { Button, Form } from 'antd';

import { Store } from 'react-notifications-component';
import { getFromApi, postToApi } from '../../apis/apis';
import MUITextField from '../../common/material-ui-components/MUITextField';
import { useTranslation } from 'react-i18next';
const schema = Yup.object().shape({
  minutes: Yup.string()
    .required('من فضلك ادخل عدد الدقائق')
    .matches(/^[0-9]+$/, 'أرقام فقط')
});
const Countdown = () => {
  const { i18n } = useTranslation();
  const [loading, setIsLoading] = useState(false);
  const [fetchedDetails, setFetchedDetails] = useState('');

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      minutes: ''
    }
  });
  useEffect(() => {
    const getFeatures = async () => {
      try {
        setIsLoading(true);
        const response = await getFromApi(`/timer-details`);
        setIsLoading(false);
        if (response.response_code === 200 && response.data !== {}) {
          setFetchedDetails(response.data);
        } else if (response.response_code === 200 && response.data === {}) {
          setFetchedDetails(null);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getFeatures();
  }, [i18n.language]);

  useEffect(() => {
    if (fetchedDetails) {
      setValue('minutes', fetchedDetails?.minutes);
    }
  }, [fetchedDetails]);

  const onSubmit = async (data) => {
    // console.log('data : ', data);
    try {
      const formData = new FormData();
      formData.append('minutes', data.minutes);
      const res = await postToApi('/save-timer', formData);
      // console.log('====================================');
      // console.log(res);
      // console.log('====================================');
      if (res.response_code === 200) {
        Store.addNotification({
          title: 'تم بنجاح',
          message: res?.message || 'البيانات المدخلة تم حفظها',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.response_code !== 200) {
        Store.addNotification({
          title: ' فشل العملية',
          message: res?.message || 'البيانات المدخلة لم يتم حفظها',
          type: 'denger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [form] = Form.useForm();

  return (
    <div className="countdown-page">
      <Form
        className="login-form minutes-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}>
        <div className="form-body">
          <MUITextField
            name="minutes"
            type="text"
            placeholder="عدد الدقائق"
            label="عدد الدقايق"
            errorMsg={errors?.minutes?.message}
            validateStatus={errors?.minutes ? 'error' : ''}
            control={control}
          />

          {/* <MUICheckbox
          name="remember"
          label={t('login_page.login_form.remember')}
          control={control}
        /> */}
          <div className="btn">
            <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
              بدأ
            </Button>
          </div>

          {/* <Link to={`/`} className='forget-password'>{t('login_page.login_form.forgetPassword')}</Link> */}
        </div>
      </Form>
    </div>
  );
};

export default Countdown;
