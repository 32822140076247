import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingShop: false,
  setLoadingShop: (v) => {},
  AllShops: [],
  setAllShops: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedShop: null,
  setSelectedShop: (servKey) => {},
  text: '',
  setText: (id) => {},
  count: false,
  setCount: (v) => {}
};

const ShopContext = createContext(INITIAL_VALUES);

export const ShopProvider = ({ children }) => {
  const [loadingShop, setLoadingShop] = useState(INITIAL_VALUES.loadingShop);
  const [AllShops, setAllShops] = useState(INITIAL_VALUES.AllShops);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [text, setText] = useState(INITIAL_VALUES.text);
  const [selectedShop, setSelectedShop] = useState(INITIAL_VALUES.selectedShop);
  const [count, setCount] = useState(INITIAL_VALUES.count);

  return (
    <ShopContext.Provider
      value={{
        loadingShop,
        setLoadingShop,
        AllShops,
        setAllShops,
        selectedServId,
        setSelectedServId,
        selectedShop,
        setSelectedShop,
        text,
        setText,
        count,
        setCount
      }}>
      {children}
    </ShopContext.Provider>
  );
};

export default ShopContext;
