import React from 'react';
import './ProfilePage.scss';
import ProfileForm from './ProfileForm';
const ProfilePage = () => {
  return (
    <div className="settings-page">
      <div className="custom-container">
        <ProfileForm />
      </div>
    </div>
  );
};

export default ProfilePage;
