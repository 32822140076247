import { store } from 'react-notifications-component';
import axios from 'axios';
import { urls } from '../urls';
import Cookies from 'js-cookie';
// import Cookies from 'universal-cookie';
// axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.withCredentials = true;
axios.defaults.adapter = require('axios/lib/adapters/http');
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['accept-language'] = 'ar';
// axios.defaults.headers.common['Accept-Language'] = 'en';

// export function setAuthorizationToken() {
//   // console.log(Cookies.get('currentUser'))
//   const userCookie = Cookies.get('currentUser');
//   if (userCookie) {
//     const user = JSON.parse(Cookies.get('currentUser'));

//   }
//   axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;

//   // }
// }
// setAuthorizationToken();

// const url = 'http://localhost:5001/admin';
const url = urls.baseUrl;

function checkResponse(response) {
  try {
    if (response.data.status === false || response.data.success === false) {
      const error = new Error(response.data.message.text);
      error.message = response.data.message.text;
      throw error;
    } else {
      const newResponse = {
        ...response.data
      };
      return newResponse;
    }
  } catch (error) {
    throw error;
  }
}

export const displayError = (error) => {
  store.addNotification({
    title: 'حدث خطأ ',
    message: error.message || 'حاول فى وقت لاحق',
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true
    }
  });
};

export const displaySuccess = (message) => {
  store.addNotification({
    title: 'success',
    message: message || 'success',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true
    }
  });
};
// export displayError;

export async function postToApi(path, body, config) {
  try {
    const response = await axios
      .create({
        baseURL: url
      })
      .post(path, body, config);
    return checkResponse(response);
  } catch (error) {
    store.addNotification({
      title: 'حدث خطأ ',
      message: error.message || 'حاول فى وقت لاحق',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 2000,
        showIcon: true,
        onScreen: true
      }
    });
    return null;
  }
}

export async function getFromApi(path, config) {
  try {
    const response = await axios
      .create({
        baseURL: url
      })
      .get(path, config);

    return checkResponse(response);
  } catch (error) {
    store.addNotification({
      title: 'حدث خطأ ',
      message: error.message || 'حاول فى وقت لاحق',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 2000,
        showIcon: true,
        onScreen: true
      }
    });
    return null;
  }
}
export async function putToApi(path, body, config) {
  try {
    const response = await axios
      .create({
        baseURL: url
      })
      .put(path, body, config);

    return checkResponse(response);
  } catch (error) {
    displayError(error);
  }
}

export async function deleteFromAgel(path, config) {
  try {
    const response = await axios
      .create({
        baseURL: url
      })
      .delete(path, config);

    return checkResponse(response);
  } catch (error) {
    displayError(error);
  }
}

export default axios.create({ baseURL: url });
