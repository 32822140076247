import React, { useContext, useEffect, useState } from 'react';
import { getFromApi } from '../../apis/apis';

import icon from '../../assets/imgs/icons/image.png';
// import close from '../../assets/imgs/icons/close.png';

import ModalContext from '../../contexts/modal-context/ModalProvider';
const ArchiveItems = () => {
  const [archiveData, setArchiveData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const { setModalComponent, setModalOpen, modalOpen } = useContext(ModalContext);

  useEffect(() => {
    const ReturnAllData = async () => {
      try {
        const response = await getFromApi('/all-estates');
        console.log(response?.data?.data);
        if (response?.data?.data.length > 0) {
          setArchiveData(response?.data?.data);
          // setLoadingHome(false);
        } else {
          // setLoadingHome(false);
        }
      } catch (error) {
        // setLoadingHome(false);
        console.log(error);
      }
    };
    ReturnAllData();
  }, []);

  const viewDtailes = (item) => () => {
    setModalOpen(true);
    setSelectedData(item);
  };

  const viewItem = () => {
    if (selectedData) {
      return (
        <div className="forget-page">
          {/* <div className="forget-header">
            <div className="close">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}>
                <img src={close} alt="close" />
              </button>
            </div>
          </div> */}
          <div className="pice-data-desc">
            <div className="info-data">اسم العقار</div>
            <div className="info-data">{selectedData?.name ? selectedData?.name : ''}</div>
            <div className="info-data">رقم القطعه</div>
            <div className="info-data">
              {selectedData?.piece_number ? selectedData?.piece_number : ''}
            </div>
            <div className="info-title">مساحة الأرض بالمتر المربع</div>
            <div className="info-data">
              {selectedData?.estate_area ? selectedData?.estate_area : ''}
            </div>

            <div className="info-title">سعر المتر المربع</div>
            <div className="info-data">
              {selectedData?.meter_price ? selectedData?.meter_price.toLocaleString() : ''}
            </div>

            <div className="info-title">قيمة العقار</div>
            <div className="info-data">
              {selectedData?.meter_price ? selectedData?.meter_price : ''}
            </div>

            <div className="info-title">ضريبه التصرفات الضربيه </div>
            <div className="info-data">
              {selectedData?.meter_price ? selectedData?.meter_price : ''}
            </div>

            <div className="info-title">نسبة السعي </div>
            <div className="info-data">
              {selectedData?.additional_tax ? selectedData?.additional_tax : ''}
            </div>

            <div className="info-title">القيمة المضافه للسعي </div>
            <div className="info-data">
              {selectedData?.additional_tax_value ? selectedData?.additional_tax_value : ''}
            </div>

            <div className="info-title">الأجمالي </div>
            <div className="info-data">
              {selectedData?.total ? selectedData?.total.toLocaleString() : ''}
              {selectedData?.total ? selectedData?.total.toLocaleString() : ''}
            </div>
          </div>
          <div></div>
        </div>
      );
    } else {
      return <div>لا بيانات</div>;
    }
  };

  useEffect(() => {
    if (modalOpen) {
      setModalComponent(viewItem());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const renderAllAreas = () => {
    if (archiveData.length > 0) {
      return archiveData.map((item) => (
        <div key={item.id} className="card-div" onClick={viewDtailes(item)}>
          <div>
            {item.images.length > 0 ? (
              <img src={item.images[0].url} alt={item.name} />
            ) : (
              <img src={icon} alt="noimage" />
            )}
          </div>
          <div className="card-data">
            <div>{item.name}</div>
            <div>{item.total}</div>
          </div>

          {/* <img src={item.icon} className="Image" alt="" /> */}
        </div>
      ));
    }
  };

  return <div className="area-continer"> {renderAllAreas()}</div>;
};

export default ArchiveItems;
