/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import EstateDetailsContext from '../../contexts/estate-details-context/EstateDetailsContext';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import { LoadingOutlined } from '@ant-design/icons';
import './HomePage.scss';
// import Infoform from '../new-item/Infoform';
import NewItem from '../new-item/NewItem';
// import Fade from 'react-reveal/Fade';

const HomePage = () => {
  DocTitleScrollTop();
  const { fetchedDetails, isLoading, setNewEstate, newEstate } = useContext(EstateDetailsContext);
  useEffect(() => {
    if (fetchedDetails?.status != 1 || !fetchedDetails) {
      setNewEstate(true);
    } else {
      setNewEstate(false);
    }
  }, [fetchedDetails?.status]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          marginTop: 72
        }}>
        <LoadingOutlined />
      </div>
    );
  }

  return (
    // <Fade delay={50}>
    <div className="home-page">
      {newEstate ? (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            marginTop: 72
          }}>
          <p>أهلا بـك</p>
          <button
            onClick={() => {
              setNewEstate(false);
            }}>
            إضافة مزاد جديد
          </button>
        </div>
      ) : (
        <NewItem />
      )}
    </div>
    // </Fade>
  );
};

export default HomePage;
