import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from '../../common/logo/Logo';
import UesrContext from '../../contexts/user-context/UserProvider';
import useSignout from '../../useSignout';

import './Layout.scss';

const AppLayout = ({ children }) => {
  const { i18n } = useTranslation();
  const { user } = useContext(UesrContext);
  const { signMeOut } = useSignout();
  return (
    <>
      <div className="header-home">
        <div>
          <p>أهلا : {user?.name}</p>
          <hr />
          <div className="custom-container">
            <div className="signout-edit-btns">
              <button className="edit-profile-btn">
                <Link to={`/profile`}>تعديل الملف الشخصي</Link>
              </button>

              {user && (
                <button className="signout-btn" onClick={signMeOut}>
                  تسجيل الخروج
                </button>
              )}
            </div>
          </div>
        </div>
        <div>
          <Logo />
        </div>
      </div>
      <div className={`app-layout app-${i18n.dir()}`}>{children}</div>
    </>
  );
};

export default AppLayout;
